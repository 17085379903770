import React from 'react';
import {StyledButton} from './Styled';

function Button({children, height, width, icon, onClick, href, test}) {
    return (
    <StyledButton test={test} href={href} width={width} height={height} onClick={onClick}>{children}</StyledButton>
    );
}

export default Button;
