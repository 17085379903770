import styled from "styled-components";

import {Input} from "../../styled/index"

const AdressInput = styled(Input)`
background: ${(props) => props.theme.colors.grey};
outline:  none;
    }
`

const PriceValue = styled.span`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
`
const PriceLabel = styled.label`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`

export { AdressInput, PriceValue, PriceLabel }
