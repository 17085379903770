import React from 'react';
import  {StyledMain} from './styled';
import About from "../../blocks/about/about";
import Advantages from "../../blocks/advantages/advantages";
import Header from "../header/header";
import Footer from "../footer/footer";
import MainPage from "../../pages/mainPage/mainPage";
import {Outlet} from "react-router-dom";

function PageWrapper() {
    return (
        <>
            <Header/>
            <StyledMain>
            <Outlet><MainPage/></Outlet>
            </StyledMain>
            <Footer/>
        </>
    );
}

export default PageWrapper;
