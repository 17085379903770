import { Navigation, Pagination, FreeMode, Scrollbar, A11y } from 'swiper/modules';
import {Swiper, SwiperSlide} from "swiper/react";
import React from "react";
import styled from "styled-components";


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import Card from "../Card/card";
import Logo from "../Logo/Logo";




const StyledSlider = styled(Swiper)`
    width: 100%;
    max-width: 100%;
    max-height: 768px;
    
    min-height: 0;
    min-width: 0;
    gap: 20px;
    align-items: flex-end;
  
    
    
`

const StyledSlide = styled(SwiperSlide)`

    height: auto;
   
`







export {StyledSlider, StyledSlide } ;
