import styled from "styled-components";
import {Li} from "../../styled/index";

const CheckboxList = styled.ul`
    margin: 0;
    list-style: none;
    padding: 0;
`
const CheckboxListItem = styled(Li)`

`

const StyledLabel = styled.label`
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

const StyledInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`
const CheckMark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    
    &:after {
        content: "";
        display: ${({$active}) => ($active ? 'block' : 'none')};
        position: absolute;
        left: 10px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    
`

export {CheckboxList,CheckboxListItem, StyledLabel, CheckMark, StyledInput }
