import React from 'react';
import {DescriptionList, DefinedTerm, DescribedTerm, DescriptionListWrapper } from './styled';

function StyledDescriptionList({ valuePairs }) {
    return (

            <DescriptionList>
                {valuePairs.map((pair, i) => (
                    <DescriptionListWrapper key={i}>
                        <DescribedTerm>{pair.property}</DescribedTerm>
                        <DefinedTerm>{pair.value}</DefinedTerm>
                    </DescriptionListWrapper>
                ))}
            </DescriptionList>

    );
}



export { StyledDescriptionList };
