import styled from "styled-components";

const Input = styled.input`
    display: flex;
    padding: 14px 131px 13px 12px;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #F6F6F6;
    font-size: 14px;
`

export default Input;
