import React from 'react';
import {ReactComponent} from "../../../assets/logo.svg";
import  {StyledLogo, LogoText} from "./Styled";


function Logo() {
    return (
<StyledLogo>
    <ReactComponent></ReactComponent>
    <LogoText>Фермерские продукты</LogoText>
</StyledLogo>
    )
}

export default Logo;
