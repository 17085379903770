import styled from "styled-components";
import section from "../../styled/section/section";

const StyledSection = styled(section)`
position: relative;
width: ${(props) => props.theme.pageWidth};
background-color: ${(props) => props.theme.colorWhite};
margin: 0 auto;
    justify-content: space-between;
    padding-left: ${(props) => props.theme.pagePadding};
    padding-right: ${(props) => props.theme.pagePadding};
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
   
`
export default StyledSection
