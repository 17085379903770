import React from 'react';
import {StyledAbout, StyledAboutTitle, Text} from "./styled";



function About() {
return (
    <StyledAbout>
        <StyledAboutTitle as='h2' size={'xlarge'}>Магазин фермерских продуктов с доставкой</StyledAboutTitle>
        <Text>Все продукты изготавливаются под заказ. Фермеры начинают готовить продукты за день до отправки заказа клиентам. Именно поэтому мы принимаем заказы заранее и доставляем продукты максимально свежими.</Text>
    </StyledAbout>
)
}

export default About;
