import styled from "styled-components";
import {P} from "../../styled/index";


const ItemCardWrapper = styled.div`
    width: 727px;
    height: 288px;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    padding: 20px;
    display: grid;
    grid-template-columns: 248px 1fr;
    gap: 20px;
    min-height: 288px;
`

const ItemCardImage = styled.img`
    width: 248px;
    height: 248px;
    flex-shrink: 0;
`

const ItemCardDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
     min-height: 288px;
`

const TabList = styled.div`
display: flex;
    gap: 10px;
    
`

const Tab   = styled.a`
    background-color: ${(props) => props.theme.grey};
    width: max-content;
    height: fit-content;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.textMainColor};
    cursor: pointer;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);



    &:hover {
        background-color: ${(props) => props.theme.colors.green};
        color: ${(props) => props.theme.colors.white};
    }
    
    &:active {
        background-color: ${(props) => props.theme.colors.green};
        color: ${(props) => props.theme.colors.white};
    }

`

const ItemCardDescription = styled(P)`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`

const PriceTag = styled.span`
    display: inline-flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 10px;
    background: #D8ECFE;
    color: #333;
    margin-top: 20px;

    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
`




export {ItemCardWrapper, ItemCardImage, ItemCardDescriptionWrapper, Tab, ItemCardDescription, TabList, PriceTag}
