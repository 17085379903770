import styled, { css } from "styled-components";


const TitleSize = {
    small: {
        fontSize: "24px",
        lineHeight: "27px",
    },
    medium: {
        fontSize: "32px",
        lineHeight: "31px",
    },
    large: {
        fontSize: "36px",
        lineHeight: "41px",
    },
    xlarge: {
        fontSize: "44px",
        lineHeight: "51px",
    }
}


 const Title = styled.h1`
    margin: 0;
    padding: 0;
    font-weight: bold;
    ${(props)=> {
        const value = TitleSize[props.size] || TitleSize.medium;
        return css`
            font-size: ${value.fontSize};
            line-height: ${value.lineHeight};`
    }}
`
export default Title;
