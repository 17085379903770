import React, {useState, useEffect} from 'react';
import Button from '../../ui/Button/Button'
import { Link } from "react-router-dom";
import {useLocation} from "react-router-dom";

function Nav () {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(location.pathname);

    useEffect(() => {

        setCurrentPage(location.pathname);
    }, [location.pathname]);

    const handleButtonClick = () => {
        setCurrentPage(currentPage === '/order' ? '/' : '/order');
        console.log('pathname:',location.pathname);
        console.log('current Page:',currentPage)
        console.log(location);
    };


    return (
        <nav>
<Link to={currentPage === '/order' ? "/" : "/order" }>
                <Button onClick={handleButtonClick}  test={currentPage}  width={'260px'} height={'60px'} >{currentPage !== '/order' ? 'Купить' : 'Главная'}</Button>
</Link>
        </nav>
    )
}

export default Nav
