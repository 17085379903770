import React from 'react';
import {P} from "../../styled/index";
import StyledSection from "./styled";
import Logo from "../../ui/Logo/Logo";

function Footer() {
    return (
        <StyledSection as="footer">
            <Logo></Logo>
            <P>Создано 2021</P>
        </StyledSection>

    )
}

export default Footer;
