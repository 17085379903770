import styled from "styled-components";
import Title from "../../ui/Title/Title";
import {P} from "../../styled/index";
import  aboutImage from '../../../assets/about.png';

const StyledAbout = styled.section`
    display: flex;
    background-color: ${(props) => props.theme.colors.blue};
    position: relative;
    height: auto;
    min-height: 627px;
    flex-direction: column;
    padding-left: ${(props) => props.theme.pagePadding};
    padding-right: ${(props) => props.theme.pagePadding};
    justify-content: center;
    
    &:after {
        background-image: url(${aboutImage});
        bottom: 0;
        right: ${(props) => props.theme.pagePadding};
        width: 446px;
        height: 550px;
        content: "";
        display: block;
        position: absolute;
        background-repeat: no-repeat;
    }
`

const StyledAboutTitle = styled(Title)`
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 637px;
    font-weight: bold;
`
const Text = styled(P)`
    margin-top: 24px;
    max-width: 637px;
`

export  {StyledAbout, StyledAboutTitle, Text};
