const features = [
    {
        id: 0,
        title: 'Еда намного вкуснее',
        owner: 'Фермерские продукты',
        isNegative: false,
        image: 'https://i.ibb.co/xGg2GfP/eat-1.png',
        about: 'Домашняя колбаса из&nbsp;мяса, соли и&nbsp;специй и&nbsp;колбаса из&nbsp;магазина&nbsp;&mdash; два настолько разных продукта, что они даже не&nbsp;родственники',
    },
    {
        id: 1,
        title: 'Просроченные продукты',
        owner: 'Магазинные продукты',
        isNegative: true,
        image: 'https://i.ibb.co/rHt4pHQ/garbage-1.png',
        about: 'Из-за большого количества товаров сотрудники магазинов не&nbsp;успевают своевременно производить замену товара',
    },
    {
        id: 3,
        title: 'Натуральные продукты',
        owner: 'Фермерские продукты',
        isNegative: false,
        image: 'https://i.ibb.co/TryK8n2/sprout-1.png',
        about: 'Поставляем местные органические продукты, выращенные без пестицидов и&nbsp;химических удобрений.',
    },
    {
        id: 4,
        title: 'Некачественное мясо',
        owner: 'Магазинные продукты',
        isNegative: true,
        image: 'https://i.ibb.co/d63ZBPp/no-meat-1.png',
        about: 'Мясные полуфабрикаты, в&nbsp;которых содержится чрезмерно много натрия, вредных жиров, консервантов',
    }
];

export default features;




