import styled from "styled-components";

export const StyledButton = styled.button`
    background-color: ${(props) => props.theme.buttonColor};
    width: ${(props) => props.width || "260px"};
    height: ${(props) => props.height || "60px"};
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out;

    background-color: ${(props) => (props.test !== '/order' || props.test ==='BuyButton' )? props.theme.buttonColor : 'transparent'};
    color: ${(props) => (props.test !== '/order' || props.test ==='BuyButton') ? props.theme.buttonTextColor : 'black'};

    &:hover,  &:active  {

        background-color: ${(props) => (props.test !== '/order' || props.test ==='BuyButton' )? props.theme.buttonHoverColor : props.theme.colors.grey};
        color: ${(props) => (props.test !== '/order' || props.test ==='BuyButton') ? 'white' : 'black'};
    }



`
