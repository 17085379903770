import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        height: 100%;
    }

    body, html {
        margin: 0 auto;
        max-width: ${(props) => props.theme.pageWidth};
    }

    body {
        position: relative;
        min-height: 100%;
        font-family: ${(props) => props.theme.fontFamily};
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        color: #333333;
        font-color: #333333;
    }
    
    a {
        text-decoration: none;
    }
`;
