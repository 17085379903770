import React from 'react';
import StyledSection from './styled';
import Logo from "../../ui/Logo/Logo";
import Nav from "../nav/nav";

function Header() {
    return (
        <StyledSection as='header'>
            <Logo></Logo>
            <Nav></Nav>
        </StyledSection>
    )
}

export default Header;
