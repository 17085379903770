import styled from "styled-components";


 const P = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
`

export default P
