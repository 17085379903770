import React from 'react';
import {StyledCard, StyledCardOwner, StyledTitle, StyledImg, StyledText, StyledCardHeader} from "./styled";

function Card({advantageInfo}) {
const {title, owner, isNegative, image, about} = advantageInfo;
    return (
        <StyledCard $isNegative={isNegative}>
            <StyledCardHeader>
            <StyledImg src={image}></StyledImg>
            <div>
            <StyledCardOwner $isNegative={isNegative}>{owner}</StyledCardOwner>
            <StyledTitle >{title}</StyledTitle>
            </div>
            </StyledCardHeader>
            <StyledText>{about}</StyledText>
        </StyledCard>
    );
}

export default Card;

