import React , {useState, useEffect}from 'react';
import {CheckboxList, CheckboxListItem, StyledLabel, CheckMark, StyledInput} from "./styled";

function StyledCheckboxList({products, setSelectedProducts, onClickProduct})  {
   const [checkedItems, setCheckedItems] = React.useState({});
    // const checkboxChange = (productId) => {
    //     setCheckedItems((prevState) => (
    //         { ...prevState, [productId]: !prevState[productId]  }
    //
    //     ));
    // }

    const checkboxChange = (productId) => {
        setCheckedItems((checkedItems) => (
        {...checkedItems, [productId]: !checkedItems[productId]}

    ));

}

useEffect(()=> {
    setSelectedProducts(checkedItems)
},[checkedItems])



    return(
<CheckboxList>
    {products.map((product, index) => (
        <CheckboxListItem key={product.id}>
            <StyledLabel onClick={() => { checkboxChange(product.id); onClickProduct(index) }}  htmlFor={product.id}>{product.name}
            <StyledInput type="checkbox" value={product.id}/>
            <CheckMark $active={checkedItems[product.id]} ></CheckMark>
            </StyledLabel>
        </CheckboxListItem>
    ))}
</CheckboxList>
    )
}


export default StyledCheckboxList;
