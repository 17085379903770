import styled from "styled-components";

const DescriptionList = styled.dl`
    margin: 0;
    padding: 0;
height: 288px;

`

const DefinedTerm = styled.dt`
    padding: 0;

`
const DescribedTerm = styled.dd`
font-weight: 700;
    padding: 0;
`

const DescriptionListWrapper = styled.div`
 
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: left;
`

export {DescriptionList, DefinedTerm, DescribedTerm, DescriptionListWrapper}
