import styled from "styled-components";
import {Input} from "../../styled";



const Layout = styled.section`
display: grid;
    grid-template-columns: min-content max-content;
    padding: 20px;
    gap: 20px;
    background-color: ${(props) => props.theme.colors.grey};
    height: 100%;
    max-width: ${(props) => props.theme.pageWidth};
`

const LeftColumn = styled.div`
    width: 353px;
    background: ${(props) => props.theme.colors.grey};
    display: grid;
    gap: 20px;
    width: 353px;
`

const CardList = styled.ul`
    display: grid;
    gap: 20px;
    padding: 0;
    margin: 0;
    width: 100%;
`

const AdressInput = styled(Input)`
background: ${(props) => props.theme.colors.grey};
outline:  none;
    }
`

const PriceValue = styled.span`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
`
const PriceLabel = styled.label`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`




export {LeftColumn, AdressInput, PriceValue, PriceLabel, CardList, Layout};
