import styled from "styled-components";
import Title from "../../ui/Title/Title";
import {Section} from "../../styled/index";



const AdvantagesSection = styled(Section)`
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: ${(props) => props.theme.pagePadding};
    padding-right: ${(props) => props.theme.pagePadding};
    justify-content: center;
    align-items: center;    
    flex-direction: column;
`

const AdvantagesTitle = styled(Title)`
    
`

const AdvantagesList = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: ${(props) => props.theme.pageWidth};
    margin-bottom: 64px;
    margin-top: 64px;
    padding: 0;
`
export {AdvantagesSection, AdvantagesTitle, AdvantagesList};
