import React from 'react';
import {BrowserRouter, Routes, Route, Router} from "react-router-dom";


import {GlobalStyle} from "./style.js";

import {ThemeProvider} from "styled-components";
import defaultTheme from '../../theme/default';


import MainPage from "../pages/mainPage/mainPage";
import OrderPage from "../pages/order/order";

import products from "../../mocks/Products";
import AppRoute from '../../const';
import scrollToTop from "../ui/ScrollToTop/scrollToTop";

import PageWrapper from "../layout/pageWrapper/pageWrapper";
import ScrollToTop from "../ui/ScrollToTop/scrollToTop";

function App() {



    return (

        <ThemeProvider theme={defaultTheme}>
            <GlobalStyle/>
            <div className="App">
                <GlobalStyle/>
                <BrowserRouter>

                    <ScrollToTop/>
                    <Routes>

                        <Route path={AppRoute.MAIN} element={<PageWrapper/>}>
                            <Route index element={<MainPage/>}/>
                            <Route path={AppRoute.ORDER} element={<OrderPage products={products}/>}/>
                        </Route>
                    </Routes>

                </BrowserRouter>


            </div>
        </ThemeProvider>

    )
        ;
}

export default App;
