import Header from "../../layout/header/header";
import PageWrapper from "../../layout/pageWrapper/pageWrapper";
import Footer from "../../layout/footer/footer";
import React from "react";
import About from "../../blocks/about/about";
import Advantages from "../../blocks/advantages/advantages";


function MainPage() {
    return (
        <>
            <About></About>
            <Advantages></Advantages>
        </>
    )
}

export default MainPage;
