import styled from "styled-components";
import section from "../../styled/section/section";

const Panel = styled(section)`
    background-color: ${(props) => props.theme.colors.white};
    min-height: 353px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    min-width: 300px;
    overflow: hidden;
    height: auto;
`

export default Panel;
