import styled from "styled-components";

export const StyledLogo = styled.a`
    display: flex;
    height: max-content;
    width: max-content;
    max-height: 44px;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
`

export const LogoText = styled.span`
    font-size: ${(props) => props.theme.logoTextSize};
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: ${(props) => props.theme.fontBold};
`


