import React, {useState} from 'react';
import styled from "styled-components";
import Title from "../Title/Title";
// import {AdressInput, PriceLabel, PriceValue} from "../../pages/order/styled";
import Button from "../Button/Button";
import {AdressInput, PriceLabel, PriceValue} from "./styled";

function Basket({totalPrice}) {
const [address, setAddress] = useState('your address');
    const onBuyClick = () => {
        
       if (!address) {
            alert('поле адреса пустое')
           return
       }

        alert(`Общая сумма заказа: ${totalPrice}\nДоставка по адресу: ${address}\n`);
    }

    return (
        <>
    <Title size={'small'}>Сделать заказ</Title>
    <AdressInput value={address} onChange={(e) => setAddress(e.target.value)} />
    <PriceLabel>Цена</PriceLabel>
    <PriceValue>{totalPrice}</PriceValue>
    <Button   onClick={onBuyClick} test={'BuyButton'} width={'100%'} >Купить</Button>
        </>
    )

}

export default Basket;
