import styled from "styled-components";
import {P, Img} from "../../styled/index";

const StyledCard = styled.article`
    width: 540px;
    min-height: 197px; 
    background-color: ${(props) => props.$isNegative ? props.theme.colors.lightRed : props.theme.colors.lightGreen};
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 22px;
`

const StyledCardOwner = styled.span`
    font-size: 14px;
    background-color: ${(props) => props.$isNegative ? props.theme.colors.red : props.theme.colors.green};
    color: ${(props) => props.theme.colorWhite};
    padding: 2px 10px;
`

const StyledTitle = styled.h3`
font-size: 18px;
font-style: normal;
font-weight: 700;
    margin: 0;
`

const StyledImg = styled(Img)`
    height: 52px;
    width: 52px;
`

const StyledText = styled(P)`
font-size: ${(props) => props.theme.textMainSize};
    
`

const StyledCardHeader = styled.div`
    display: flex;
    gap: 20px;
`

export {StyledCard, StyledCardOwner, StyledTitle, StyledImg, StyledText, StyledCardHeader};

