import React, {useRef, useState, useEffect} from 'react';
import Panel from '../../ui/Panel/Panel';
import {LeftColumn, AdressInput, PriceValue, PriceLabel, CardList, Layout} from "./styled";
import Title from "../../ui/Title/Title";
import Button from "../../ui/Button/Button";
import {StyledSlide, StyledSlider} from "../../ui/Swiper/Swiper";
import 'swiper/css';
import {FreeMode, Mousewheel, Scrollbar, Pagination} from "swiper/modules";
import ItemCard from "../../ui/ItemCard/itemCard";
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer";
import StyledCheckboxList from "../../ui/CheckboxList/CheckboxList";
import Basket from "../../ui/Basket/Basket";








function OrderPage({products}) {
    const [selectedProducts, setSelectedProducts] = useState({});
    const [swiperRef, setSwiperRef] = useState(null);
    const [totalPrice, setTotalPrice] = useState (0);




    const handleOnClickProduct = (index) => {
        const productId = products[index].id;
        const isSelected = selectedProducts[productId]

        if (!isSelected) {
            swiperRef.slideTo(index);
        }
        calculatePrice(index)
    }

    useEffect(() => {
        calculatePrice();
    }, [selectedProducts]);

    const calculatePrice = () => {
        let totalPrice = Object.keys(selectedProducts)
            .filter(id => selectedProducts[id]) // Отфильтровать только выбранные товары
            .map(id => products[id].price)   // Получить массив цен выбранных товаров
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0); // Просуммировать цены

        setTotalPrice(totalPrice);
    };


    return (
        <>

            <Layout>
                <LeftColumn>

                    <Panel>
                        <Title size={'small'}>Выберите продукты</Title>
                        <StyledCheckboxList products={products} setSelectedProducts={setSelectedProducts} onClickProduct={handleOnClickProduct} />
                    </Panel>
                    <Panel>
                        <Basket totalPrice={totalPrice} />
                        {/*<Title size={'small'}>Сделать заказ</Title>*/}
                        {/*<AdressInput/>*/}
                        {/*<PriceLabel>Цена</PriceLabel>*/}
                        {/*<PriceValue>1200</PriceValue>*/}
                        {/*<Button width={'100%'}>Купить</Button>*/}
                    </Panel>
                </LeftColumn>
                <Panel>
                <CardList $products={products}  >

                    <StyledSlider
                        onSwiper={swiperinstance => setSwiperRef(swiperinstance)}
                        slidesPerView={3}
                        freeMode={true}
                        direction={"vertical"}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Mousewheel, Scrollbar, Pagination]}
                        className="mySwiper"
                    >
                        {products.map(product => (
                        <StyledSlide key={product.id}><ItemCard product={product}/></StyledSlide>
                        ))}


                    </StyledSlider>
                </CardList>
                </Panel>


            </Layout>

        </>
    )
}

export default OrderPage;
