import React from 'react';
import {AdvantagesList, AdvantagesSection, AdvantagesTitle} from "./styled";
import Button from "../../ui/Button/Button";
import Card from "../../ui/Card/card";
import features from "../../../mocks/Advantages";
import {Li} from "../../styled/index";
import {Link} from "react-router-dom";

function Advantages() {
    return (

<AdvantagesSection>
    <AdvantagesTitle size={'large'}>Почему фермерские продукты лучше?</AdvantagesTitle>
    <AdvantagesList>
        {features.map((item) =>
            <Li key={item.id}>
                <Card advantageInfo={item} ></Card>
            </Li>
        )}




    </AdvantagesList>
    <Link to={'/order'}>
    <Button test={'BuyButton'}>Купить</Button>
    </Link>
</AdvantagesSection>
    )
}

export default Advantages;
