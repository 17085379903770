import React from 'react';
import {StyledDescriptionList} from '../DescriptionList/DescriptionList'
import {ItemCardDescriptionWrapper, ItemCardImage, ItemCardWrapper, Tab, ItemCardDescription, TabList, PriceTag } from "./styled";
import Title from "../Title/Title";






function ItemCard({product}) {
    const TabData  = [
        {
            title: 'Описание',
            content: product.description
        },
        {
            title: 'Характеристики',
            content: product.specifications
        },
        {
            title: 'Свойства',
            content: product.structure
        }
    ]

    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index)

    }

    return (
        <>
            <ItemCardWrapper >
                <ItemCardImage src={product.image}></ItemCardImage>
                <ItemCardDescriptionWrapper>
                    <Title size={'medium'}>{product.name}</Title>
                    <TabList>
                        {TabData.map((tab, index) => (
                            <Tab onClick={()=> handleTabClick(index) }   key={index}>{tab.title}</Tab>
                        ))}
                    </TabList>
                    {activeTab === 0 && <><ItemCardDescription>{product.description}</ItemCardDescription>
                        <PriceTag>{`${product.price} руб. / ${product.weight} гр.`}</PriceTag></>}


                    {activeTab === 1 && <StyledDescriptionList valuePairs={product.specifications}></StyledDescriptionList>}
                    {activeTab === 2 && <StyledDescriptionList valuePairs={product.structure}></StyledDescriptionList>}

                </ItemCardDescriptionWrapper>
            </ItemCardWrapper>


        </>
    )

}

export default ItemCard;
