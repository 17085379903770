import styled from "styled-components";

 const Section = styled.section`
    display: flex;
    padding-left: ${(props) => (props.theme.pagePadding)};
    padding-left: ${(props) => (props.theme.pagePadding)};
    box-sizing: border-box;
    margin: 0;
`
export default Section
