const defaultTheme = {
    textMainColor: '#333333',
    textMainSize: '18px',
    colorBlack: '#333333',
    colorWhite: '#ffffff',
    colorPositive: '#E1EDCE',
    colorNegative: '#F8DDD7',
    pagePadding: '90px',
    fontFamily: '"Inter", "Arial", sans-serif',
    pageWidth: "1280px",
    buttonHover: "red",
    buttonDisabled: "grey",
    logoTextSize: "28px",
    fontBold: "700",
    buttonColor: "#FC9B27",
    buttonTextColor: "#FFFFFF",
    buttonHoverColor: "#FC7427",
    buttonActiveTextColor: "#FC7427",

    colors: {
        blue: '#D8ECFE',
        lightGreen: '#E1EDCE',
        green: '#88AA4D',
        lightRed: '#F8DDD7',
        red: '#F75531',
        grey: '#F6F6F6',
        white: '#ffffff'
    }

};

 export default defaultTheme;

